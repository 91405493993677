<template>
  <div class="d-inline">
    <span v-if="!owner.id">{{ owner.fullName }}</span>
    <v-menu
      v-if="owner.id"
      open-on-hover
      offset-x 
      nudge-right="1"
      nudge-top="5"
      right
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-inline-block"
          v-bind="attrs"
          v-on="on"
        >
          <span class="primary--text text--lighten-3">{{ owner.fullName }}</span>
        </div>
      </template>

      <v-card>
        <v-card-text class="pa-0">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <UserPhoto :size="40" photoSize="thumb" :id="owner.userid" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="primary--color body-1">{{ owner.fullName }}</span>
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                    <a :href="`mailto:${owner.email}`" class="text-decoration-none">{{ owner.email }}</a>
                    <div v-if="owner.organization && owner.role">{{ owner.employeeRole }}</div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>  
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    id: {
      type: String,
      required: true
    }
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      ownerData: null,
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'id': {
      handler(id) {
        if (id) this.$store.dispatch('users/getUserUid', id)
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      users: state => state.users.users
    }),

    owner: function () {
      const owner = this.users.find(u => u.userid == this.id)
      return owner || { fullName: 'guest' }
    }
  },
}
</script>